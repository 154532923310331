import "./style.css";

import React, {useContext, useEffect, useState} from "react";
import {accessToken, gFetch} from "../../core/fncs";
import {GlobalContext} from "../GlobalContext";

let aborterFetchUserInfo = [] // = new AbortController() // на каждый запрос нужен свой абортарий! Один на все случаи делать нельзя!

const fetchUserInfo = (config, userId, setter, stateSetter)=> {

    if(aborterFetchUserInfo[userId]){ // наличие абортария - по совместительству признак того, что какой-то запрос в процессе исполнения
        // если какой-то запрос в процессе выполнения:
        // aborterFetchUserInfo.abort() // отменить выполняющийся запрос
        return; // значит новый запрос не выполнять — хватит и одного
    }
    aborterFetchUserInfo[userId] = new AbortController(); // на каждый запрос нужен свой абортарий

    stateSetter({type: "wait", description: "Подготавливается запрос"}) // setter( [] );

    const accToken = accessToken()
    const req = config["GETUSERINFO_JSPROMISE"] // fetch('https://auth.content.spb.ckb-rubin.local/v1/clients/sgr/users/read',{method:'POST',headers:{'Authorization':'Bearer :accToken'},body:JSON.stringify({user:{id:':userSub'}})}).then(it=>(it.json())).then(it=>(it.user))
        .replace(":accToken", accToken )
        .replace(":userSub", userId )

    eval(req)
        .then( usr => {
            stateSetter({type: "wait", description: "Подготавливается информация о пользователе"})
            const pic = usr.avatar || usr.picture;

            if( pic && pic.slice(0,4).toLowerCase() === "http" ){ //если url, то получаем по нему картинку
                stateSetter({type: "pic", description: "Подготавливается картинка пользователя"})
                const kache = sessionStorage.getItem(pic);
                if (kache) {
                    usr.avatar = kache;
                    setter(usr);
                    aborterFetchUserInfo[userId] = null;
                    stateSetter({type: "ok"})
                }else {
                    gFetch(pic, {method: "GET", headers: {"Authorization": "Bearer " + window.acc}})
                        .then(it => { return it.blob() })
                        .then((it) => {
                            const fr = new FileReader();
                            fr.readAsDataURL(it);
                            fr.onloadend = () => {
                                usr.avatar = "";
                                if(fr.result.indexOf("data:application/json") < 0) { //если не ошибка
                                    sessionStorage.setItem(pic, fr.result); //кешируем
                                    usr.avatar = fr.result; //сохраняем у пользователя вместо url саму картинку
                                    setter(usr); // перерисовываем инфо о пользоваетеле
                                }else{
                                    setter(usr); // если ошибка, то не кешируем
                                }
                                aborterFetchUserInfo[userId] = null;
                                stateSetter({type: "ok"})
                            }
                        })
                }
            }else{
                setter(usr);
                aborterFetchUserInfo[userId] = null;
                stateSetter({type: "ok"})
            }
        })
        .catch( err => {
            aborterFetchUserInfo[userId] = null; // уничтожение абортария - запрос завершён (с ошибкой), он уже не нужен
            if(err.name === "AbortError") return;
            stateSetter({type: "error", description: err.message })
        })
}

export default function User({
                       className="",
                       sub = "",
                       children = "",
                   }) {
    const {config, curUserInfo, path, setPath} = useContext(GlobalContext)

    const [state, setState] = useState({type: ""});
    const [userInfo, setUserInfo] = useState( {} );

    // const sub = path.replace(/^.*\/users\/([^\/]+)\/?.*$/, "$1");

    useEffect(() => {
        const userId = (sub||children)
        if(!userId || userId == "null" || userId == "undefined") return;
        const lsKey = "user-"+userId
        const cache = sessionStorage.getItem( lsKey )
        if( cache ){
            setUserInfo( JSON.parse(cache) )
            setState({type: "ok"})
        }else{
            fetchUserInfo( config, userId
                ,(ui)=>{
                    setUserInfo(ui)
                    sessionStorage.setItem( lsKey, JSON.stringify(ui) )
                }, setState);
        }
    }, []);

    return (<>
              {(state.type != "ok"
              ? ( (curUserInfo.sub && state.type != "" ) ? <>{(sub||children)}</> : <></>)
              : <span
                          className={"user "+className}
                          data-sub={userInfo.sub}
                          title={
                                    (userInfo.name || userInfo.display_name || (userInfo.given_name+" "+userInfo.family_name) )
                                  + "\r\n"
                                  + (userInfo.nickname || userInfo.login || userInfo.username)
                                }
                      >
                  <span className="user-nickname">{userInfo.nickname || userInfo.login || userInfo.username}</span>
                  { (userInfo.avatar || userInfo.picture)
                  ? <img className="user-avatar" alt="😎" src={userInfo.avatar || userInfo.picture}/>
                  : <i className={"user-avatar-empty"}></i>
                  }
                  <span className="user-name">{
                      (userInfo.name || userInfo.display_name || (userInfo.given_name+" "+userInfo.family_name) )
                  }</span>
                </span>
              )}
  </>);
}
