import React, {useId, useState, useEffect, useRef} from "react"

import '@fortawesome/fontawesome-free/css/all.min.css'
import "./style.css"
import {gFetch} from "../fncs";

export const Radio = ({
                          name = "",
                          value = "",
                          checked = false,
                          label = "",
                          className = "",
                          id = "",
                          onClick = (event) => {},
                          position = "before",
                          readOnly = false,
                      }) => {

    // const [isChecked, setIsChecked] = useState(checked);

    const uid = useId();
    id = id || uid;

    // const dref = useRef()

    return (<p className={"uiRadio ui-position-"+position+" "+(checked ? "active" : "")}>
            {position === "after" ? (<label htmlFor={id}>{label}</label>) : <></>}
            <input
                    type="radio"
                    className={className}
                    id={id}
                    name={name}
                    value={value}
                    onClick={e => {
                        if(readOnly) return;
                        Array.from(document.querySelectorAll(".uiRadio.active > input[name='" + e.currentTarget.name + "']")).forEach((i) => {
                            if(!(i.checked)) {
                                i.parentNode.classList.remove("active");
                            }
                        })
                        if( e.currentTarget.checked ){
                            e.currentTarget.parentNode.classList.add("active")
                        }
                        onClick(e)
                    }}
                    defaultChecked={checked}
                    readOnly={readOnly}

            />
            {position === "before" ? (<label htmlFor={id}>{label}</label>) : <></>}
        </p>
    )
}

export const Checkbox = ({
                             name = "",
                             value = "",
                             checked = false,
                             label = "",
                             className = "",
                             id = "",
                             onClick = (event) => {},
                             position = "before",
                             readOnly = false,
                         }) => {

    const uid = useId();
    const rId = (id ? id : uid);

    const [isChecked, setIsChecked] = useState(checked);

    return (<p className={"uiCheckbox ui-position-"+position+" "+(isChecked ? "active" : "")}
               id={rId}
    >
            {(position === "after")
                ? <label htmlFor={"i-"+rId}>{label}</label>
                : <></>
            }
            <input
                    type="checkbox"
                    className={className}
                    id={"i-"+rId}
                    name={name}
                    value={value}
                    onClick={e => {
                        setIsChecked( !(isChecked) )
                        onClick(e)
                    }}
                    checked={isChecked}
                    readOnly={readOnly}
            />
            {(position === "before")
                ? <label htmlFor={"i-"+rId}>{label}</label>
                : <></>
            }

        </p>
    )
}

export const File = ({
                         label = (<></>), // отображаемая подпись
                         name = "",  // имя элемента формы
                         value = "", // дефолтное отображаемое значение
                         className = "", // классы корневого элемента компонента
                         placeholder = "Выберите файл", // заглушка, на случай, если файл не указан
                         id = "", // идентификатор корневого элемента компонента (у элемента формы идентификатор будет `i`+id), если не указано - будет сгенерированно автоматом уникальное значение
                         accept = "", // перечень дозволенных для выбора mime-типов или расширений файлов (должон начинаться с точки)
                         multiple = false, // признак множественного выбора файлов
                         required= false, // признак обязательности заполнения
                         onChange = (inputElement) => {}, // функция-обработчик на после изменения значения
                         readOnly = false,
                     }) => {
    let realId = useId();
    if(id) realId = id;

    const onCh = (files) => {
        if(readOnly) return;
        let fileNames = [];
        for (let i = 0; i < files.length; i++) {
            const n = files[i].name;
            fileNames.push(n);
        }
        const tt = document.getElementById(`t-${realId}`); // плейсхолдер / имя файла
        const dd = document.getElementById(`d-${realId}`); // крестик отмены выбранного файла
        tt.className = "value";
        if (fileNames.length) {
            tt.classList.add("ext-" + fileNames[0].slice(1 + fileNames[0].lastIndexOf(".")).toLowerCase());
            tt.textContent = fileNames.join("; ");
            dd.style.display = "inline-block";
        } else {
            dd.style.display = "none";
            if (value) {
                tt.textContent = value;
                tt.classList.add("ext-" + value.slice(1 + value.lastIndexOf(".")).toLowerCase());
            } else {
                tt.textContent = placeholder;
                tt.classList.add("placeholder");
                tt.classList.remove("value");
            }
        }

    }

    return (
        <p id={realId}
           className={`uiFile ${className}`}
           onDrop={e=>{
               e.preventDefault();
               e.currentTarget.classList.remove("dragover");
               if(readOnly) return false;
               const fileInput = document.getElementById(`i-${realId}`);

               fileInput.files = e.dataTransfer.files;

               for(let i = 0; i < e.dataTransfer.files.length; i++){
                   if(!( accept==="" || accept.indexOf(e.dataTransfer.files[i].type)+1 )){
                       alert(`Тип ${e.dataTransfer.files[i].type} не поддерживается. Допустимые типы: ${accept}`);
                       fileInput.value = null;
                   }
               }
               if(!multiple && fileInput.files.length > 1) {
                   alert("Нельзя много"); // Доступна загрузка только одного файла // Нет возможности множественной загрузки файлов
                   fileInput.value = null;
               }

               // fileInput.dispatchEvent(new Event("change"));
               onCh( fileInput.files )
               onChange( fileInput )
           }}
           onDragOver={e=>{
               e.preventDefault();
               e.currentTarget.classList.add("dragover");
           }}
           onDragLeave={e=>{
               e.preventDefault();
               e.currentTarget.classList.remove("dragover");
           }}
        >

            { label === (<></>) ? {label} : (<label htmlFor={`i-${realId}`}>{label}</label>) }
            <button
                onClick={e => {
                        e.preventDefault();
                        document.getElementById(`i-${realId}`).click(e)
                    }}
            ><Ico i="document-open" />Выбор файла</button>

            <span id={`t-${realId}`}
                  className={ `${value ? " value" : " placeholder"} ext-${value.slice(1 + value.lastIndexOf(".")).toLowerCase()}` }
                  onClick={(e) => { document.getElementById(`i-${realId}`).click(e)}}
            >{value || placeholder}</span>

            <input style={{display: "none"}}
                   id={`i-${realId}`}
                   name={name}
                   type="file"
                   placeholder={placeholder}
                   accept={accept}
                   multiple={multiple}
                   required={!readOnly && required}
                   onChange={e => {
                       onCh(e.currentTarget.files)
                       onChange( e.currentTarget )
                   }}
                   readOnly={readOnly}
            />

            <sup id={`d-${realId}`}
                  onClick={e => {
                      const fileInput = document.getElementById(`i-${realId}`);
                      fileInput.value = null;
                      // fileInput.dispatchEvent(new Event("change"));
                      onCh( fileInput.files )
                      onChange( fileInput )
                  }}
                 className="delFile"
                 style={{
                     display:"none",
                     cursor: "pointer",
                 }}
            >❌</sup>
        </p>
    )
}

/*
export const Select = ({
                         label = "",
                         name = "",
                         value = "",
                         className = "",
                         id = "",
                         data = [],
                         onChange = (event) => {},
                         withEmpty = true,
                         multiple=false,
                         required=false,
                         size=1,
                     }) => {

    const [val, setVal] = useState("");

    if (!id) id = useId();

    useEffect(()=>{
        setVal(value||"")
    }, []);

    return html`<p class="uiSelect ${className}" data-selected="${ data.filter(it => {return val===it.val }).map(it=>{return it.val}).join("; ") }">
        ${ (label||"").trim().length === 0 ? "" : html`<label for="${id}">${label}</label>` }
        <select
                id="${id}"
                name="${name}"
                multiple=${multiple}
                required=${required}
                size=${size}
                onChange=${(event) => {
                    setVal(event.currentTarget.value)
                    onChange(event)
                }}
        >
            ${ withEmpty ? html`<option></option>` : "" }
            ${ data
                    .map(it => {
                        return html`<option value="${it.val}" selected=${val===it.val} >${it.title||it.val}</option>`
                    })
             }
        </select>
    </p>`
}
*/

export const Selector = ({
    disabled = false,
    source = "",
    itemShower = (item)=>{},
    onChange = (items)=>{},
    value = [],
    test = (a,b)=>(a==b),
    className="",
}) => {
    const [items, setItems] = useState([])
    useEffect(()=>{
        if(source){
            gFetch(source).then((it)=>(it.json())).then((it)=>{ setItems(it) })
        }
    }, [source])

    const [selecteds, setSelecteds] = useState( [] )
    useEffect(()=>{ setSelecteds(value||[]) },[value])

    const toggleSelect = (item)=>{
        if(disabled) return;

        let newSelecteds = selecteds||[];
        if( newSelecteds.filter( (a)=>( test(item, a) ) ).length > 0 ){
            newSelecteds = newSelecteds.filter( sl => ( !test(sl, item) ))
        }else{
            newSelecteds.push(item);
        }
        setSelecteds( newSelecteds.slice() )
    }

    useEffect(()=>{
        onChange && onChange(selecteds)
    }, [selecteds])

    const isActive = (item) => ( selecteds.filter( (a)=>( test(item, a) ) ).length > 0 )

    return (<ul
        className={"ui-selector "+className}
        data-disabled={disabled}
    >
        {
            items.map((it)=>(
                <li
                    className={"ui-selector-item " + ( isActive(it) ? "active" : "")}
                    onClick={(e)=>{ toggleSelect(it) }}
                >
                    {
                        itemShower(it)
                    }
                </li>
            ))
        }
    </ul>)
}

export const Ico = ({
                        i = "",
                        className="",
                        title="",
                        id="",
                        onClick = (e) => {},
                    }) => {
    return (<i
        className={`fa fa-${i} ${className}`}
        title={title}
        id={id}
        onClick={onClick}
    >&nbsp;</i>)
}

export default {
    Radio,
    Checkbox,
    File,
    // Select,
    Selector,
    Ico,
}
