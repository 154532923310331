import "./style.css";

import React, {useContext} from "react";
import {linkTo, saveToken} from "../../core/fncs";
import ui from "../../core/ui";
import {GlobalContext} from "../GlobalContext";

export default function Header({
}) {

  const {config, curUserInfo, path, setPath} = useContext(GlobalContext)

  return (<header>
                  <span className="logo">
                      <img
                         src="/favicon.ico"
                         alt="iy"
                         onClick={()=>{ setPath("/") }}
                      />
                  </span>
              {
                      curUserInfo.sub ? (
                          <p className={"userInfo"}>
                              <img alt="😎" src={curUserInfo.picture64 || curUserInfo.picture}/>
                              <span>{curUserInfo.displayName || curUserInfo.sub}</span>
                              <a href={window.location + "?apus="}
                                 onClick={(e) => {
                                     e.preventDefault();
                                     saveToken({}); // сбросить-обнулить токены
                                     window.location = window.location + "?apus="; // просто, чтоб перезагрузилась страница
                                 }}
                              >
                                  <ui.Ico i={"right-from-bracket"}/>
                                  <span>Выход</span>
                              </a>
                          </p>
                      ) : (<p className={"login"}>
                          <a href={linkTo("/login")}
                             onClick={(e) => {
                                 e.preventDefault();
                                 window.location = linkTo("/login?redirectUrl=" + window.location);
                             }}
                          >
                              <ui.Ico i={"user-tie"}/>
                              <span>Авторизация</span></a>
                      </p>)
              }


              <p>
                  <a href={"/"}
                     className={ ( path == "/" || path == "" || path.indexOf("/src/")===0 ) ? "active" : "" }
                     onClick={(e)=>{ setPath(e) }}
                  >
                      <ui.Ico i={"clone"}/>
                      <span>Шаблоны</span>
                  </a>
                  <a href={"/mans/"}
                     className={ ( path.indexOf("/mans/")===0 ) ? "active" : "" }
                     onClick={(e)=>{ setPath(e) }}
                  >
                      <ui.Ico i={"circle-info"}/>
                      <span>Документация</span>
                  </a>
                  <a href={"/promo/"}
                     className={ ( path.indexOf("/promo/")===0 ) ? "active" : "" }
                     onClick={(e)=>{ setPath(e) }}
                  >
                      <ui.Ico i={"info"}/>
                      <span>Презентации</span>
                  </a>

                  { (config["GIT_LINK_CONTENT"] || "").trim().indexOf("<") === 0
                  ? <a
                          target={"_blank"}
                          href={ config["GIT_LINK_URL"]}
                          dangerouslySetInnerHTML={{__html: config["GIT_LINK_CONTENT"]}}
                    />
                  : <a
                          target={"_blank"}
                          href={ config["GIT_LINK_URL"]}
                    >
                      <img
                          src={ config["GIT_LINK_CONTENT"] }
                          alt={".git"}
                      />
                    </a>
                  }
              </p>

      </header>
  );
}
