import "./style.css";

import React, {useContext, useEffect, useState} from "react";
import {gFetch} from "../../../core/fncs";
import ui from "../../../core/ui";

const fetchStorages = function (setter) { //получаем перечень хранилищ
    gFetch("/storages")
        .then(it => {
            return it.json()
        })
        .then( it => {
            setter( it )
        })
}

export default function Storages ({
                                      curStorageName = "",
                                      chStorage = (storage)=>{},
                                  }) {

    const [storages, setStorages] = useState([]); //useState создаёт переменную storages, состояние которой меняет метод setStorages (([]) - дефолтное значение)

    const [force, setForse] = useState(true); // true - значит принудительно вызвать chStorage на необходимом источнике

    useEffect(() => { // выполняет код внутри себя когда изменяет значение переменной в  [] (если оно есть), в данном случае выполняетя один раз с пустым значением, потом вычисляется значение в fetchStorages, вызывается setter  и запускается перерисовка - storages
        fetchStorages(setStorages);
    }, []);

    return ( (storages && storages.length > 1)
            ?   <fieldset id="storages">
                    <legend>Источник</legend>
                    {storages.map(st => {
                        if( (!curStorageName && st.default) || ( force && curStorageName === st.name ) ) {
                            chStorage(st)
                        }
                        return (<ui.Radio
                            className={"storage storage-type-" + st.type}
                            id={"radio-" + st.name}
                            name="radioStorages"
                            value={st.name}
                            onClick={() => {
                                setForse(false)
                                chStorage(st)
                            }}
                            checked={curStorageName === st.name || (!curStorageName && st.default)}
                            label={<>
                                {/*
                                <ui.Ico
                                    i={{"sql": "database", "file": "folder"}[st.type] || st.type}
                                    className={"small"}
                                />
                                */}
                                {st.name}
                            </>}
                            position="before"
                        />)
                    })}
            </fieldset>
            : ((storages[0] && chStorage(storages[0])) || <></>)
    )
}
