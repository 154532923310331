import {toast} from "./toast";

const apusCode   = "apus";
const clientPort = "3000";
const serverPort = "3080";

const jwtAccTokenUrlItem = "CONFIG_JWT_ACCTOKEN_URL";

// export const getStateObj = ()=>( (window.history.state || {}).obj || {} )

const getJwtAccTokenUrl = () => (localStorage.getItem(jwtAccTokenUrlItem))
export const setJwtAccTokenUrl = (url) => (localStorage.setItem(jwtAccTokenUrlItem, url))

export const linkTo = (str)=>(
        (window.location.port === clientPort && str.indexOf("/")===0 )
            ? window.location.origin.replace(":"+clientPort, ":"+serverPort)
            : ""
    )
    + ((str.indexOf("/")!==0 && str.indexOf("http")!==0) ? window.location.pathname.replace(/[^\/]+$/, "") : "")
    + (str||"")

export const getOrCreate = (elId="defArea", tag = "div", parent = document.body) =>{
    const el = document.getElementById(elId) || document.createElement(tag);
    el.id = el.id || elId;
    if(!el.parentNode) parent.appendChild(el);
    return el;
}

export const gFetch = async (str, params) => {
    if( refreshTokensInProcess ) refreshTokensInProcess.then( _ => ( gFetch(str, params) ) )

    if (str !== "/config") {// для работы gFetch требуется нормально получение этого конфига!!!
        const accToken = accessToken()
        if (accToken && accToken != "null") {
            params = params || {}
            params.headers = params.headers || {}
            params.headers["Authorization"] = "Bearer " + (accToken)
        }
    }
    return await fetch(linkTo(str), params)
        .then(async it => {
            if (it.status === 524) {
                return doRefreshTokens().then(async () => (gFetch(str, params)))
            } else {
                if(it.status >= 400){
                    const content = await it.text()
                    const obj = {
                        status: "error",
                        type: it.status,
                        content: content,
                    }
                    toast(obj)
                    return new Promise((resolve,reject)=>{resolve({json: ()=>(
                        Object.assign({permissions:[]}, obj)
                        )})});
                }else {
                    return it
                }
            }
        })
}

export const accessToken  = ()=>( JSON.parse( atob( sessionStorage.getItem(apusCode)|| "") || "{}" ).access_token  )
export const refreshToken = ()=>( JSON.parse( atob( sessionStorage.getItem(apusCode)|| "") || "{}" ).refresh_token )

export const saveToken = (token)=>{
    token = token || {};
    const newApus = Object.assign(JSON.parse( atob( sessionStorage.getItem( apusCode )  || "") || "{}" ), {access_token: token.access_token, refresh_token: token.refresh_token})
    sessionStorage.setItem(apusCode, btoa( JSON.stringify(newApus) ))
}

let refreshTokensInProcess = false
async function doRefreshTokens() {
    const jwtAccTokenUrl = getJwtAccTokenUrl() // useContext(GlobalContext).config["JWT_ACCTOKEN_URL"]
    if(refreshTokensInProcess || !jwtAccTokenUrl){
        return refreshTokensInProcess;
    }

    refreshTokensInProcess = fetch(jwtAccTokenUrl, {
        method: "POST",
        headers: {"Content-Type": "text/plain"},
        body: JSON.stringify({
            refresh_token: refreshToken(),
            grant_type: "refresh_token",
        }),
    })
        .then((it) => {
            return it.json();
        })
        .then((it) => {
            // if(it.errors) appendErrors(it.errors);
            saveToken(it);
            refreshTokensInProcess.then()
            refreshTokensInProcess = false;
            return this;
        });
    return await refreshTokensInProcess
}
