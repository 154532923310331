import "./style.css";

import React, {useContext, useEffect, useId, useState} from "react";
import ui from "../../../core/ui";
import User from "../../User";
import {linkTo} from "../../../core/fncs";

export default function Template({
                      template = {},
                      chTemplate = (template)=>{},
                      current = false,
                      delTemplate = (template)=>{}, // метод удаления шаблона
                      showFormForEditTemplate= (template)=>{}, // метод отображения формы редактирования шаблона
                      onResort= (ord)=>{}, //функция сортировки
                      order={}, // текущая сортировка
                  }){

    const tId = useId()

    if(!(template.code)){ // шаблон-заглушка
        return (
            <tr className="Template">
                <th className="template-type">

                </th>
                <th className="template-code">
                    <b>Код</b>
                    <p className="resort" title="Сортировка коду шаблона">
                        <span
                            onClick={() => {
                                (order.by === "code" && order.dir === "↓") || onResort({by: "code", dir: "↓"})
                            }}
                            className={"↓ " + ((order.by === "code" && order.dir === "↓") ? "active" : "")}
                        ><ui.Ico i="sort-down"/></span>
                        <span
                            onClick={() => {
                                (order.by === "code" && order.dir === "↑") || onResort({by: "code", dir: "↑"})
                            }}
                            className={"↑ " + ((order.by === "code" && order.dir === "↓") ? "active" : "")}
                        ><ui.Ico i="sort-up"/></span>
                    </p>
                    <em className="template-keywords">ключевые; слова</em>
                </th>
                <th className="template-title">
                    Наименование
                    <p className="resort" title="Сортировка по наименованию">
                        <span
                            onClick={() => {
                                (order.by === "title" && order.dir === "↓") || onResort({by: "title", dir: "↓"})
                            }}
                            className={"↓ " + ((order.by === "title" && order.dir === "↓") ? "active" : "")}
                        ><ui.Ico i="sort-down"/></span>
                        <span
                            onClick={() => {
                                (order.by === "title" && order.dir === "↑") || onResort({by: "title", dir: "↑"})
                            }}
                            className={"↑ " + ((order.by === "title" && order.dir === "↑") ? "active" : "")}
                        ><ui.Ico i="sort-up"/></span>
                    </p>
                </th>

                <th className="template-actions"></th>
                <th className="template-author"></th>
            </tr>
    )
    }else {
        switch (template.code) {
            case "…": { // отрисовка процесса получения данных
                return (<tr className="Template"><td className="loader" colSpan="5">
                                Получение данных…
                                <br/>
                                чуточку обождите
                            </td></tr>)
            }
            case "×": { // отрисовка ошибки
                return (<tr className="Template"><td className="err" colSpan="5">
                                Что-то пошло не так…
                                <br/>
                                {template.description}
                            </td></tr>)
            }
            default: {
                return (
                    <tr
                        className={"Template type-" + (template.type.toLowerCase()) + " " + (current ? "active" : "")}
                        id={"tmpl-" + tId}
                        key={template.src + ":" + template.code}
                        title={template.description}
                    >
                        <td
                            className="template-type"
                            onClick={() => {
                                chTemplate(template)
                            }}
                        >
                            <img src={"/"+template.type.slice(0,3).toLowerCase()+"1.png"} alt={template.type}/>
                        </td>
                        <td
                            className="template-code"
                            onClick={() => {
                                chTemplate(template)
                            }}
                        >
                            <b>
                                <a
                                    onClick={(e) => {
                                        e.preventDefault()
                                    }}
                                    href={linkTo("/form/" + encodeURIComponent(template.code) + "/" + encodeURIComponent(template.src) + "?withDetails=t&target=_blank")}
                                >{template.code}</a>
                            </b>
                            <em className="template-keywords">{template.keywords}</em>
                        </td>
                        <td
                            className="template-title"
                            onClick={() => {
                                chTemplate(template)
                            }}
                        >
                            {template.title}
                        </td>
                        <td
                            className="template-actions"
                        >
                            {(1 + (template.permissions || []).indexOf("upd"))
                                ? <span onClick={() => {
                                    showFormForEditTemplate(template)
                                }} className="upd" title="Редактировать">
                                  <ui.Ico i="pencil"/>
                              </span>
                                : <></>
                            }
                            {(1 + (template.permissions || []).indexOf("del"))
                                ? <span onClick={() => {
                                    delTemplate(template)
                                }} className="del" title="Удалить">
                                  <ui.Ico i="trash-can"/>
                              </span>
                                : <></>
                            }
                        </td>
                        <td
                            className="template-author"
                        >
                            <User sub={template.author.sub}/>
                        </td>
                    </tr>
                )
            }

        }
    }
}
