import React from "react";
import {linkTo} from "../fncs";

export default function LinkMD({
                                   setPath = (p)=>{},
                                   href="",
                                   title="",
                                   className = "",
                                   children = <></>,
}) {
    return(
        <a href={href}
              title={title}
              className={className}
                onClick={(e)=>{
                    e.preventDefault()
                    if(href.slice(0,1) === "#"){
                        const trgt = document.getElementById( href.slice(1) );
                        if(trgt){
                            trgt.scrollIntoView({behavior: "smooth"})
                        }else{
                            setPath(linkTo(href))
                            // window.location.href = href;
                        }
                    }else {
                        setPath(linkTo(href))
                    }
                }}
              >{children}</a>
    )
}
