import './style.css';

import React, {useEffect, useState} from "react";
import {gFetch, linkTo, setJwtAccTokenUrl} from "../core/fncs";

import Header    from "./Header";
import Footer    from "./Footer";
import Renderer  from "./Renderer";
import Man       from "./Man";
import {fillConfig, GlobalContext} from "./GlobalContext";
import {toast} from "../core/toast";
import Promo from "./Promo";

// let sgrWS = null

const fetchSessData = function ( setter ) {
    gFetch("/sess")
      .then(it => {
        return it && it.json()
      })
      .then(usr => {
          if(usr) {
              usr.state = true
          }else{
              usr = {state: false}
          }
        const lsKey =  "user-"+usr.sub
        if(usr.acc) window["acc"] = usr.acc;

        usr.displayName = [usr.family_name, usr.given_name, usr.middle_name].join(" ").trim();
        if(usr.displayName.length === 0) usr.displayName = usr.nickname;
        if( usr.picture && usr.picture.slice(0,4).toLowerCase() === "http" ){ //если url, то получаем по нему картинку
          const pic = usr.picture;
          const kache = sessionStorage.getItem(pic);
          if (kache) {
            usr.picture = kache;
            setter(usr);
          }else {
              gFetch(pic, {method: "GET", headers: {"Authorization": "Bearer " + usr.acc}})
                .then(it => {
                  return it.blob()
                })
                .then((it) => {
                  const fr = new FileReader();
                  fr.readAsDataURL(it);
                  fr.onloadend = () => {
                    usr.picture = "";
                    if(fr.result.indexOf("data:application/json") < 0) { //если не ошибка
                      sessionStorage.setItem(pic, fr.result); //кешируем
                      usr.picture = fr.result; //сохраняем у пользователя вместо url саму картинку
                      sessionStorage.setItem(lsKey, JSON.stringify( usr )) // записать в кэш
                      setter(usr); // перерисовываем инфо о пользоваетеле
                    }else{
                      setter(usr); // если ошибка, то не кешируем
                    }
                  }
                })
                .catch(it => { // ну, не вышло, и не вышло (
                  console.log(it)
                  setter(usr);
                })
          }
        }else {
          sessionStorage.setItem(lsKey, JSON.stringify( usr )) // записать в кэш
          setter(usr)
        }
      })
}

function App() {

    let [config, setConfig] = useState({})
    let [curUserInfo, setCurUserInfo] = useState({state: false});
    let [path, setP] = useState( decodeURIComponent( document.location.pathname || "/" ) );
    // let [wsSend, setWsSend] = useState((data)=>{})

    function setPath(
        p,
        replace=false,
        // closer = ()=>{}
    ){

        if( typeof(p) === "object" ) {
            if ( ("preventDefault" in p) && ("currentTarget" in p) ) {
                if ("href" in p.currentTarget) {
                    p.preventDefault();
                    const a = p.currentTarget
                    if("host" in a){
                        p = a.href.split(a.host)[1]
                    }else {
                        p = a.href
                    }
                }else{
                    return false;
                }
            }
        }

        const url = p.replace("/#!/", "/")

        setP(url)

        const data = {path: url /*, closer: closer*/}

        const prevPath = ( (window.history.state && window.history.state.path) ? window.history.state.path : "" )
        if( url != prevPath ) {
            if (replace) {
                window.history.replaceState(data, "", url)
            } else {
                window.history.pushState(data, "", url)
            }
        }
    }

    useEffect(() => {
        (()=>{
            try {
                const srch = window.location.search || window.location.hash.split("?")[1]
                const ca = (srch[0]==="?" ? srch.slice(1) : srch).split("=")
                if(ca[0]==="apus") {
                    sessionStorage.setItem(ca[0], ca[1])
                    setPath( document.location.pathname )
                }
            }catch(_){
            }
        })()

        fillConfig(setConfig);

        window.addEventListener("popstate", (e)=>{
            // удалить все диалоги!
            Array.from(document.querySelectorAll("dialog")).forEach( dia => {
                dia.close(); dia.remove();
            })
            setPath( document.location.pathname )
        })

        fetchSessData( setCurUserInfo );

        const sp = document.location.href.split("/#!")[1]
        if(sp) setPath( decodeURIComponent(sp), true )

    }, []);

    useEffect(() => {
        // сохранить в браузере урл для обновления токена
        // так проще, нежели переписывать gFetch как кастомный хук
        // а если его не переписывать на кастомный хук, то в нём нельзя использовать useContext для доступа к конфигу
        setJwtAccTokenUrl(config["JWT_ACCTOKEN_URL"]) // sessionStorage.setItem("CONFIG_JWT_ACCTOKEN_URL", config["JWT_ACCTOKEN_URL"])
    }, [config])

    //
    // const wsSend = (txt, tryQty=7) => {
    //     try {
    //         sgrWS.send(txt)
    //     }catch (e) {
    //         (tryQty < 7) && console.error(tryQty, e)
    //         if( tryQty > 0 ) {
    //             setTimeout(() => {
    //                 wsSend(txt, tryQty - 1)
    //             }, 500)
    //         }
    //     }
    // }
    // useEffect(() => {
    //     if(curUserInfo && curUserInfo.sub) {
    //         if(sgrWS) {
    //             wsSend("hello§"+curUserInfo.sub)
    //         }else {
    //             try{
    //                 sgrWS = new WebSocket(linkTo("/ws").replace("http", "ws"));
    //                 sgrWS.onmessage = (e) => {
    //                     // console.log(" > " + e.data)
    //                     let tst = {}
    //                     tst.type = e.data.split("§")[0]
    //                     tst.content = e.data.slice(1 + tst.type.length)
    //                     toast(tst)
    //                 }
    //                 sgrWS.onopen = (e) => {
    //                     sgrWS.send("hello§" + curUserInfo.sub)
    //                 }
    //                 sgrWS.onclose = (e) => {
    //                     sgrWS.close()
    //                 }
    //             }catch(e){
    //                 console.log("init ws error", e)
    //             }
    //         }
    //     }
    // }, [curUserInfo]);

    return (
        <GlobalContext.Provider value={{config: config, curUserInfo: curUserInfo, setPath: setPath, path: path}}>
            <Header/>
            <div className={"main"}>
              {[
                  (( path === "" || path === "/" || path.indexOf("/src/")===0 )       ? <Renderer /> : <></>)
                  ,((new RegExp("^/mans/")).test(path)                                 ? <Man      /> : <></>)
                  ,((new RegExp("^/promo/")).test(path)                                ? <Promo    /> : <></>)
              ]}
            </div>
            <Footer />
        </GlobalContext.Provider>
    );
}

export default App;
