import React from "react";
import ui from "../../../core/ui";

export default function ButtonCategoryAdd({
                                       onClick = (e)=>{}, // функция изменения перечня категорий
                  }) {

    return (
        <span
            onClick={onClick}
            className="add"
            title="Добавить"
        >
            <ui.Ico i="plus" />
        </span>
    )

}
