import "./style.css";

import React, {useContext, useEffect, useState} from "react";

import {getOrCreate, gFetch} from "../../../core/fncs";
import ui from "../../../core/ui";
import ReactDOM from "react-dom/client";
import CategoryEditor from "./CategoryEditor";
import {GlobalContext} from "../../GlobalContext";
import ButtonCategoryAdd from "./ButtonCategoryAdd";

const allCatsCode = "∞";
const woCatCode   = "∅";

export const allCats = {id: allCatsCode, code: allCatsCode, title: "[всё]"}
export const woCat   = {id: woCatCode, code: woCatCode, title: "[без категории]"}

export default function Categories({
                                       categories = [],
                                       onChange = (cat)=>{}, // функция фильтрации шаблонов
                                       parentId = "", // ID вышестоящей категории
                                       curent= "",    // код текущей категории
                                       allCategoriesSetter = null, // (cats)=>{}, // функция изменения перечня категорий
                                       curStorage= "", // текущее хранилище
                                       canAddCategory= false, // можно-ли добавлять новые категории сюда?
                                       withSystems=true, // с системными категориями
                  }) {

    const {config, curUserInfo, path, setPath} = useContext(GlobalContext)

    const saveCategory = function (formData) {
        if( curUserInfo && curUserInfo.sub ) {
            gFetch("/categories", {method: "POST", body: formData})
                .then( it => { return it.json() })
                .then( it => {
                    if( it.type === "err" ){
                        alert( it.id +": "+ it.title + "\r\n\r\n" + it.category)
                    }else {
                        const rt = formData.get("requestType");
                        it.code = it.title
                        if(rt === "categoryDelete"){
                            categories = categories.filter((c) => { return c.id !== it.id });
                            it.code = woCatCode
                        }else {
                            if(rt === "categoryAdd"){
                                categories.push(it)
                            }else{
                                categories[categories.findIndex((c) => { return c.id === it.id })] = it
                            }
                        }
                        allCategoriesSetter( Object.assign([], categories) )
                        onChange(it);
                    }
                });
        }else{
            alert("Вы не авторизованы")
        }
    }

    const showFormForCategory = function (cat) {
        if( curUserInfo && curUserInfo.sub ) {
            cat.src = curStorage
            ReactDOM.createRoot(getOrCreate()).render((
                <CategoryEditor
                    cat={cat}
                    onSave={(form) => {
                        saveCategory(new FormData(form));
                        form.reset();
                    }}
                />
            ))

        }else{
            alert("Вы не авторизованы")
        }
    }

    const showConfirmForCategoryErase = function (cat) {
        if( curUserInfo && curUserInfo.sub ) {
            const promt = "Я, в трезвом уме и светлой памяти, действительно желаю удалить категорию "+cat.title+"."
            // if(confirm("Уудалить категорию "+cat.title+"?")){
            if(promt === window.prompt(`Если действительно требуется удалить категорию ${cat.title}, то введите текст:\n\r\n\r${promt}`)){
                const fd = new FormData();
                fd.append("id", cat.id);
                fd.append("src", curStorage);
                fd.append("requestType", "categoryDelete");
                saveCategory(fd)
            }
        }else{
            alert("Вы не авторизованы")
        }
    }

    if(withSystems) {
        if (!(categories.filter(it => {
            return it.code === woCat.code
        }).length)) categories.push(woCat)

        if (!(categories.filter(it => {
            return it.code === allCats.code
        }).length)) categories.push(allCats)
    }else{
        categories = categories.filter(it => {
            return !(it.code === woCat.code || it.code === allCats.code )
        })
    }

    return (<>

        { ( !parentId && canAddCategory )
        ? <span className="category-actions">
                <ButtonCategoryAdd onClick={() => {showFormForCategory({title: "Новая корневая категория", category: null})}}/>
          </span>
        : <></>}

        <ul id={"category-"+parentId}>
            {
                categories
                    .filter( it => { return it.id })
                    .filter( it=>{
                        // console.info( parentId, it.category,  parentId=="" || (it.category==parentId) );
                        return (parentId==="" && !it.category) || (it.category===parentId)
                    })
                    .sort((a,b)=>{
                        if([allCats.code, woCat.code].indexOf(b.code)+1) return 7;
                        if([allCats.code, woCat.code].indexOf(a.code)+1) return -1;
                        return (a.title > b.title) ? 1 : -1;
                    })
                    .map(it=>{
                        it.code = it.code||it.title

                        it.cur = ( (curent==="" && it.id===woCat.code ) || (curent === it.code))

                        return (
                            <li id={"cat-"+(it.id)} className="category">
                                <p
                                    className={"category-caption "+ (it.cur ? "active" : "")}
                                >
                                    <span className="category-title"
                                          onClick={(e)=>{
                                              onChange(it)
                                          }}
                                    >
                                        { it.code === allCats.code && <ui.Ico i={"folder-closed fa-" + (it.cur ? "solid" : "regular")}/> }
                                        { it.code === woCat.code && <ui.Ico i={"folder-closed fa-" + (it.cur ? "solid" : "regular")}/> }
                                        { [allCats.code, woCat.code].indexOf(it.code) < 0 && <ui.Ico i={"folder fa-" + (it.cur ? "solid" : "regular")}/> }
                                        {it.title}
                                    </span>
                                    <span className="category-actions">
                                        { (1+(it.permissions||[]).indexOf("upd")  && allCategoriesSetter)
                                        ? <span
                                                onClick={(e)=>{
                                                    showFormForCategory(it)
                                                }}
                                                className="upd"
                                                title="Переименовать"
                                          >
                                                <ui.Ico i="pencil" />
                                          </span>
                                        : <></>}
                                        { (1+(it.permissions||[]).indexOf("del")  && allCategoriesSetter)
                                        ? <span
                                                onClick={(e)=>{
                                                    showConfirmForCategoryErase(it)
                                                }}
                                                className="del"
                                                title="Удалить"
                                          >
                                                <ui.Ico i="trash-can" />
                                          </span>
                                        : <></>}
                                        { (1+(it.permissions||[]).indexOf("add")  && allCategoriesSetter)
                                        ? <ButtonCategoryAdd onClick={()=>{showFormForCategory({title: "Новая категория", category: it.id})}} />
                                        : <></> }
                                    </span>
                                </p>
                                <Categories
                                    categories={categories}
                                    onChange={onChange}
                                    parentId={it.id}
                                    curent={curent}
                                    allCategoriesSetter={allCategoriesSetter}
                                    curStorage={curStorage}
                                    canAddCategory={ (1+(it.permissions||[]).indexOf("add")) }
                                />
                            </li>
                        )
                    })
            }
        </ul>
    </>)

}
