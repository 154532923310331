import "./style.css"

import React, {useEffect, useId, useState} from "react";

import ui from "../ui"

const highLightLangs = {
    js: {
        equa: /(\b\s*=\s*\b)/g,
        quot: /(('.*?')|(".*?")|(".*?(?<!\)"))|('.*?(?<!\)')|`))/g,
        comm: /((\/\*([^*]|[\r\n]|(\*+([^*/]|[\r\n])))*\*+\/)|(\/\/.*))/g,
        logi: /(%=|%|-|\+|\*|&amp;{1,2}\|\|{1,2}|&lt;=|&gt;=|&lt;|&gt;|!={1,2}|={2,3})/g,
        numb: /(\d+(\.\d+)?(e\d+)?)/g,
        func: /(?<=^|\s*)(async|await|console|alert|Math|Object|Array|String|class(?!\s*=)|function)(?=\b)/g,
        decl: /(?<=^|\s*)(var|let|const)/g,
        pare: /([()])/g,
        squa: /([\[\]])/g,
        curl: /([{}])/g,
    },
    xml: {
        head: /(&lt;\?xml.*\?&gt;)/g,
        tags: /(?<=&lt;\/?)([^\s&]+)(?=\s|&gt;)/g,
        angl: /(&lt;\/?|&gt;)/g,
        attr: /((?<=<i class=xml_tags>[^\s&]+<\/i>)[^<]+)/g,
        quot: /(('.*?')|(".*?")|(".*?(?<!\)"))|('.*?(?<!\)')|`))/g,
        comm: /(<i class=xml_angl>&lt;<\/i>!--.*?--<i class=xml_angl>&gt;<\/i>)/gms, // !!! GMS
        comm_be: /(<i class=xml_angl>&lt;<\/i>!--)/g,
        comm_en: /(--<i class=xml_angl>&gt;<\/i>)/g,
    },
    sql: {
        blck: /(declare|begin|end|\?:=)/g,
        equa: /(\b\s*:=\s*\b)/g,
        quot: /(('.*?')|(".*?")|(".*?(?<!\)"))|('.*?(?<!\)')|`))/g,
        comm: /((\/\*([^*]|[\r\n]|(\*+([^*/]|[\r\n])))*\*+\/)|(--.*))/g,
        logi: /(%=|%|-|\+|\*|and|or|between|&lt;=|&gt;=|&lt;|&gt;|!={1,2}|={2,3})/g,
        numb: /(\d+(\.\d+)?(e\d+)?)/g,
        func: /(?<=^|\s*)(select|from|order by|where|inner|join|group by|having|return)(?=\b)/g,
        // decl: /(?<=^|\s*)(var|let|const)/g,
        pare: /([()])/g,
        squa: /([\[\]])/g,
        curl: /([{}])/g,
    },
}


const elOnKeyDown = (e)=>{
    if(e.keyCode === 9){
        e.preventDefault();
        e.data = "\t";
        elOnInput(e)
    }
}
const elOnInput = (e) => {
    const el = e.currentTarget || e.target;
    const data = e.data || (e.nativeEvent ? e.nativeEvent.data : null)

    if( 1+[">", "\t"].indexOf(data)) {
        const sel = window.getSelection();
        const selFrom = sel.anchorOffset;
        let   selTo = sel.focusOffset;
        const range = sel.getRangeAt(0);
        const pn = range.commonAncestorContainer; // узел, в котором стоит курсор

        if( data === "\t" ){
            pn.textContent =
                pn.textContent.slice(0, selFrom) // область от начала текста до начала выделения
                + data // выделенная часть, которую меняем на то, что нужно вставить(на табуляцию)
                + pn.textContent.slice(selTo) // оставшееся часть текста (то, что после выделения)
            ;
            selTo++;
        }
        if (el.getAttribute("data-lang") === "xml" && data === ">") {
            if( 0>["<>", "/>", "?>"].indexOf(pn.textContent.slice(sel.anchorOffset-2, sel.anchorOffset))) {
                const closeTag = pn.textContent.slice(0, sel.anchorOffset).replace(/^.*(?<=<([^\s&><]+))(?:\s[^&<]+)?>?$/g, "$1")
                const closeText = "</" + closeTag + ">";
                pn.textContent = pn.textContent.slice(0, selFrom) + closeText + pn.textContent.slice(selTo);
            }
        }
        range.setStart(pn, selTo);
        range.collapse(true);
    }
    const x = document.createElement("DIV");
    x.innerHTML = el.innerHTML.replace(/<div>/ig, "\r\n").replace(/<\/div>/ig, "");

    const tf = el.parentElement.parentElement
        .querySelector("#"+el.id.replace("by__", ""))
    ;
    tf.textContent = x.textContent;
    tf.value = tf.textContent;

    highLightRedraw(el);
}
const elOnScroll = (e) => {
    const el = e.target;
    const elc = document.getElementById("for__"+el.id);
    elc.scrollTop = el.scrollTop;
    elc.scrollLeft = el.scrollLeft;
}
const elOnPaste = (e) => {
    e.preventDefault();

    const el = e.target;

    const sel = window.getSelection();
    const selFrom = sel.anchorOffset;
    const selTo   = sel.focusOffset;
    const range = sel.getRangeAt(0);
    // const pn = (range.commonAncestorContainer == el) ? el : range.commonAncestorContainer.parentNode;
    const pn = range.commonAncestorContainer;
    const txt = (e.clipboardData.getData("text/plain"));
    pn.textContent = pn.textContent.slice(0, selFrom) + txt + pn.textContent.slice(selTo);

    try {
        range.setStart(pn, selFrom + txt.length);
        range.collapse(true);
    }catch(e){
        console.log( pn, selFrom, txt.length )
    }

    elOnInput(e) // el.dispatchEvent(new Event("input"));
}
const elOnDragOver = (e) => {
    e.preventDefault();
    e.target.classList.add("dragover");
}
const elOnDragLeave = (e) => {
    e.preventDefault();
    e.target.classList.remove("dragover");
}
const elOnDrop = (e) => {
    const el = e.target;
    const accept = el.getAttribute("data-accept") || "";
    e.preventDefault();
    el.classList.remove("dragover");

    if(e.dataTransfer.files.length > 1) {
        return alert("Нельзя много");
    }

    for(let i = 0; i < e.dataTransfer.files.length; i++){
        if(!( accept==="" || (accept||"").indexOf(e.dataTransfer.files[i].type)+1 )){
            return alert(`Тип ${e.dataTransfer.files[i].type} не поддерживается, можно только: ${ accept }`);
        }
        const reader = new FileReader();
        reader.readAsText(e.dataTransfer.files[i]);
        reader.onload = () => {
            el.textContent = reader.result;
            elOnInput(e) // el.dispatchEvent(new Event("input"));
        }
    }
}

const highLightRedraw = el => {
    const dataLang = el.dataset.lang;
    const langObj = highLightLangs[dataLang];

    el.querySelectorAll("*") // удаление всех атрибутов всех вложенных узлов - оного не должно быть!
        .forEach((n)=>{
            Object.keys(n.attributes)
                .forEach((k)=>{
                    n.attributes[k] && n.attributes[k].name && n.removeAttribute(n.attributes[k].name)
                })
        });

    let html = el.innerHTML;
    Object.keys(langObj).forEach(function (key) {
        html = html.replace(langObj[key], `<i class=${dataLang}_${key}>$1</i>`);
    });

    el.parentElement.querySelector(`#for__${el.id}`).innerHTML = html;
};

const toggleFullScreenByEsc = (e)=>{
    if(e.key === "Escape"){
        e.preventDefault()
        e.stopPropagation()
        let p = e.target
        while(!(p.classList.contains("highLight"))) p = p.parentNode;
        p.classList.remove("fullScreen");
        p.removeEventListener("keydown", toggleFullScreenByEsc)
    }
}
const toggleFullScreen = (e)=>{
    e.target.parentNode.classList.toggle("fullScreen");
    e.target.parentNode.querySelector(".highLight_editable").focus();
    if( e.target.parentNode.classList.contains("fullScreen") ){
        e.target.parentNode.addEventListener("keydown", toggleFullScreenByEsc)
    }else{
        e.target.parentNode.removeEventListener("keydown", toggleFullScreenByEsc)
    }
}

export const highLightMake = function (it, lang) {
    // if(!it.id) it.id = uid; // TODO: надо генерить уникальные ID
    const p  = document.createElement("DIV");
    p.classList.add("highLight");

    const el = document.createElement("DIV");
    el.id = "by__" + it.id;
    el.contentEditable = true;
    el.setAttribute("data-lang", lang || it.getAttribute("data-lang"));
    el.spellcheck = false;
    el.autocorrect = "off";
    el.autocapitalize = "off";
    el.className = it.className;
    el.classList.add("highLight_editable");

    el.textContent = it.textContent;

    it.style.display = "none";

    const elc = document.createElement("DIV");
    elc.id = "for__" + el.id;
    elc.classList.add("highLight_colors");

    el.addEventListener("input",   elOnInput);
    el.addEventListener("scroll",  elOnScroll);
    el.addEventListener("paste",   elOnPaste);
    el.addEventListener("keydown", elOnKeyDown);

    el.addEventListener("dragover",  elOnDragOver);
    el.addEventListener("dragleave", elOnDragLeave);
    el.addEventListener("drop",      elOnDrop);

    p.appendChild(elc); p.appendChild(el);
    it.parentElement.insertBefore(p, it);

    const tfse = document.createElement("i");
    tfse.classList.add("tfs", "tfs-expand", "fa", "fa-expand");
    tfse.addEventListener("click", toggleFullScreen);
    p.appendChild(tfse);

    const tfsc = document.createElement("i");
    tfsc.classList.add("tfs", "tfs-compress", "fa", "fa-compress");
    tfsc.addEventListener("click", toggleFullScreen);
    p.appendChild(tfsc);

    highLightRedraw(el);
}

export default function HighLighter({
                         language = null,
    name = "",
    style = {},
    content = "",
                                        readOnly = false,
                     }){
    const uid = CSS.escape( useId().replaceAll(":","_") )

    useEffect(() => {
            const el = document.querySelector(`#by__${uid}`);
            el.spellcheck = false;
            el.autocorrect = "off";
            el.autocapitalize = "off";

            const elc = document.querySelector(`#for__by__${uid}`);
            elc.spellcheck = false;
            elc.autocorrect = "off";
            elc.autocapitalize = "off";


            const x = document.createElement("DIV");
            x.textContent = content;

            document.querySelector(`#by__${uid}`).innerHTML = x.innerHTML.replace(/(\r?\n)+/g, "<br/>");

            highLightRedraw(el);
            }, []);

    return (<>
        <div className="highLight"
             style={style}
        >
            <div
                id={`for__by__${uid}`}
                className="highLight_colors"
                data-lang={language}
                autoCorrect="off"
                autoCapitalize="off"
            />
            <div
                id={`by__${uid}`}
                className="highLight_editable"
                contentEditable={ !readOnly }
                autoCorrect="off"
                autoCapitalize="off"
                data-lang={language}
                onInput={(e)=>{ readOnly || elOnInput(e)}}
                onScroll={elOnScroll}
                onPaste={(e)=>{ readOnly || elOnPaste(e)}}
                onKeyDown={(e)=>{ readOnly || elOnKeyDown(e)}}
                onDragOver={(e)=>{ readOnly || elOnDragOver(e)}}
                onDragLeave={(e)=>{ readOnly || elOnDragLeave(e)}}
                onDrop={(e)=>{ readOnly || elOnDrop(e)}}
            />
            <ui.Ico i={"expand tfs tfs-expand"} onClick={toggleFullScreen} />
            <ui.Ico i={"compress tfs tfs-compress"} onClick={toggleFullScreen} />
        </div>
        <textarea id={uid}
                  name={name}
                  data-lang={language}
                  style={{display: "none"}}
                  defaultValue={content}
                  readOnly={readOnly}
        />
    </>)
}

