import "./style.css";

import React, {useEffect, useState, useId, useRef} from "react";
import ui from "../ui"

const diaClose = (dia)=>{
    if(!dia || dia.tagName.toUpperCase() !== "DIALOG") return false;

    Array.from( dia.querySelectorAll("form") ).forEach((f)=>{f.reset()});
    dia.removeEventListener("keydown", diaCloseByEsc);
    dia.close();
    dia.remove();
    return true;
}

const diaCloseByEsc = (e)=>{
    if(e.key === "Escape"){
        e.preventDefault()
        e.stopPropagation()
        let dia = e.target
        while(!(dia.tagName.toUpperCase() === "DIALOG" )) dia = dia.parentNode;
        return diaClose(dia)
    }
    return false;
}

export const diaCloser = (e) =>{
    if(e.target.tagName !== "DIALOG") return false;

    if(e.type==="mousedown"){
        e.target.setAttribute("data-mdd", Date.now());
        return false;
    }
    if(e.type==="mouseup"){
        const md = e.target.getAttribute("data-mdd");
        if((md||0) === 0) return;
        const df = Date.now() - md;
        if(df > 300) return false;
    }

    const rect = e.target.getBoundingClientRect();
    if( !(
        rect.top  <= e.clientY && e.clientY <= rect.top  + rect.height &&
        rect.left <= e.clientX && e.clientX <= rect.left + rect.width
    ) ) {
        const dia = e.target;
        return diaClose(dia);
    }
    return false;

}

const makeDialogDraggable = () =>{
    function getCoordsByTransform3D(o) {
        if (o === "")
            return {x: 0, y: 0, z: 0};
        const t = o.match(
            /translate3d\((?<x>[-.\d]+)(?:px)?,\s*(?<y>[-.\d]+)(?:px)?,\s*(?<z>[-.\d]+)(?:px)?\)/
        );
        return !t || !t.groups ? {x: 0, y: 0, z: 0} : {
            x: Number(t.groups.x),
            y: Number(t.groups.y),
            z: Number(t.groups.z)
        };
    }

    function getDia(o) {
        let t = !1;
        for (let e = 0; e < o.length; e++) {
            const s = o[e];
            if (s.tagName === "BUTTON" || s.tagName === "A") return null;
            if (s.tagName === "DIALOG" && t) return s;
            // s.dataset && Object.hasOwn(s.dataset, "dialogDragger") && (t = !0);
            s.hasAttribute && s.hasAttribute("data-dialog-dragger") && (t = !0); //
        }
        return null;
    }

    const globalPointerEvent = (o) => {
        const t = o.target;
        if (!t) return;
        let dia;
        if (t.tagName === "DIALOG") dia = t;
        else {
            const r = getDia( o.composedPath() );
            if (!r) return;
            dia = r;
        }
        const {x: s, y: g} = getCoordsByTransform3D(dia.style.transform);
        const a = o.clientX - dia.offsetLeft - s
        const i = o.clientY - dia.offsetTop  - g;
        const m = -dia.offsetLeft;
        const h = window.innerWidth  - dia.offsetLeft - dia.offsetWidth;
        const p = -dia.offsetTop;
        const x = window.innerHeight - dia.offsetTop  - dia.offsetHeight;
        if (a < 0 || a > dia.offsetWidth || i < 0 || i > dia.offsetHeight) return;

        function transformTo(n, r) {
            const f = n - dia.offsetLeft - a;
            const y = r - dia.offsetTop - i;
            const L = Math.min(Math.max(m, f), h);
            const v = Math.min(Math.max(p, y), x);

            // const oldTrans = dia.style.transform

            dia.style.transform = `translate3d(${L}px, ${v}px, 0)`;

            // if(dia.style.transform != oldTrans) dia.move = true;
        }

        dia.setPointerCapture(o.pointerId);

        const onPointerMove = (n) => transformTo(n.clientX, n.clientY);

        const onTouchMove = (n) => {
            n.preventDefault();
            transformTo(n.touches[0].clientX, n.touches[0].clientY);
        };

        // dia.addEventListener("click", diaCloser);

        dia.addEventListener("pointermove", onPointerMove);
        dia.addEventListener("touchmove",   onTouchMove);
        dia.addEventListener("pointerup", (n) => {
            n.preventDefault();
            dia.releasePointerCapture(n.pointerId);
            dia.removeEventListener("pointermove", onPointerMove);
            dia.removeEventListener("touchmove",   onTouchMove);
        }, {once: !0} );
    };

    (function() {
        window.removeEventListener("pointerdown", globalPointerEvent);
        window.addEventListener("pointerdown", globalPointerEvent);
    })();
}
makeDialogDraggable();

export default function Dialog({
                       title = (<></>),
                       id = "",
                       className = "",
                       children = (<></>),
                       buttonAction = (e)=>{},
                       buttonCaption = "Ок",
                       closeAction = (e)=>{},
                   }) {
    const tId = useId();
    const dref = useRef();
    const [open, setOpen] = useState(true);

    useEffect(()=>{
         if(open){
             dref.current.showModal();
         }else{
             dref.current.close();
             dref.current.remove();
             closeAction && closeAction();
         }
    }, [open]);

    return (<>
        <dialog ref={dref}
                id={id || tId}
                aria-labelledby={(id || tId) + "-title"}
                className={className}
                onMouseDown={(e) => {
                    if (diaCloser(e)) {
                        closeAction && closeAction(e)
                    }
                }}
                onMouseUp={(e) => {
                    if (diaCloser(e)) {
                        closeAction && closeAction(e)
                    }
                }}
                onCancel={(e) => {
                    if (diaCloser(e)) {
                        closeAction && closeAction(e)
                    }
                }}
                onKeyDown={(e) => {
                    if (diaCloseByEsc(e)) {
                        closeAction && closeAction(e)
                    }
                }}
        >
            <h3 id={(id || tId) + "-title"} data-dialog-dragger>{title}</h3>
            <ui.Ico i={"close close"} onClick={(e) => {
                setOpen(false)
            }}/>
            {children}
            {buttonCaption
                ? <fieldset className="buttons">
                    <button className="submit" onClick={(e) => {
                        buttonAction(e, (r) => {
                            r && setOpen(false)
                        })
                    }}>{buttonCaption}</button>
                    <button className="close reset" onClick={(e) => {
                        setOpen(false)
                    }}>Отмена
                    </button>
                </fieldset>
                : <></>
            }
        </dialog>
    </>)
}
