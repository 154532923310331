import {createContext} from "react";
import {gFetch, linkTo} from "../core/fncs";

let fetchConfigInProcess = false
export const fillConfig = function ( setter ) {
    if(fetchConfigInProcess) return fetchConfigInProcess;
    const rez = gFetch("/config")
        .then(it => {
            return it && it.json()
        })
        .then(it => {
            setter(it || {})
            fetchConfigInProcess = false;
        })
    fetchConfigInProcess = rez;
    return rez;
}

export const GlobalContext = createContext({
    config: {},
    curUserInfo: {},
    path: "",
    setPath: (newPath)=>{},
    // ws: {},
})
