import "./style.css";

import React, {useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom/client";

import {getOrCreate, gFetch} from "../../core/fncs";
import ui from "../../core/ui";
import Categories, {allCats, woCat} from "./Categories";
import Storages from "./Storages";
import Template from "./Template";
import Builder from "./Builder";
import Editor from "./Editor";
import {GlobalContext} from "../GlobalContext";

let aborterFetchCategories // = new AbortController() // на каждый запрос нужен свой абортарий! Один на все случаи делать нельзя!
const fetchCategories = function (setter, curStorage) {
    document.body.style.cursor = "wait";
    if(aborterFetchCategories){ // наличие абортария - по совместительству признак того, что какой-то запрос в процессе исполнения
        // если какой-то запрос в процессе выполнения:
        aborterFetchCategories.abort() // отменить выполняющийся запрос
    }
    // setter( [{code: "…"}] );
    aborterFetchCategories = new AbortController(); // на каждый запрос нужен свой абортарий
    gFetch(
        "/categories?src="+curStorage
        ,{
            signal: aborterFetchCategories.signal // привязка запроса к абортарию
        })
        .then(it => {
            return it.json()
        })
        .then( it => {
            setter(it);
            aborterFetchCategories = null; // уничтожение абортария - запрос завершён (успешно), он уже не нужен
            document.body.style.cursor = "";
        })
        .catch( err => {
            // setter( [{code: "×", description: err.message}] );
            console.log(err);
            aborterFetchCategories = null; // уничтожение абортария - запрос завершён (с ошибкой), он уже не нужен
            document.body.style.cursor = "";
        })
}

let aborterFetchTemplates // = new AbortController() // на каждый запрос нужен свой абортарий! Один на все случаи делать нельзя!
const fetchTemplates = function (setter, curStorage, curCategory) {
    document.body.style.cursor = "wait";
    if(aborterFetchTemplates){ // наличие абортария - по совместительству признак того, что какой-то запрос в процессе исполнения
        // если какой-то запрос в процессе выполнения:
        aborterFetchTemplates.abort() // отменить выполняющийся запрос
    }
    setter( [{code: "…"}] );
    aborterFetchTemplates = new AbortController(); // на каждый запрос нужен свой абортарий
    gFetch(
        "/templates?src="+curStorage
        ,{
            signal: aborterFetchTemplates.signal // привязка запроса к абортарию
        })
        .then(it => {
            return it.json()
        })
        .then( it => {
            setter( it );
            aborterFetchTemplates = null; // уничтожение абортария - запрос завершён (успешно), он уже не нужен
            document.body.style.cursor = "";
        })
        .catch( err => {
            setter( [{code: "×", description: err.message}] );
            console.log(err);
            aborterFetchTemplates = null; // уничтожение абортария - запрос завершён (с ошибкой), он уже не нужен
            document.body.style.cursor = "";
        })
}


export default function Renderer({}) {

    const {config, curUserInfo, path, setPath} = useContext(GlobalContext)

    const [curTmpl, setCurTmpl] = useState({});
    const [allTemplates, setAllTemplates] = useState([]);
    const [templates, setTemplates] = useState([]);

    const [curStorage, setStorage] = useState("");

    const [canAddCategoryInRoot, setCanAddCategoryInRoot] = useState(false);
    const [visibleCategoryTree, setVisibleCategoryTree] = useState(false);
    const [tmplAddWithoutCategory, setTmplAddWithoutCategory] = useState(false);
    const [tmplAdd, setTmplAdd] = useState(false);

    const [categories, setCategories] = useState([]);
    const [curCategory, setCurCategory] = useState(allCats.code);

    const [order, setOrder] = useState({by:"code"});

    useEffect(() => {
        if(path.indexOf("/src/")===0 ){
            setStorage( decodeURIComponent( path.replace(/\/src\/([^\/]+)\/?.*/, "$1") ))

            if(path.indexOf("/cat/")>0 ){
                setCurCategory( decodeURIComponent( path.replace(/\/src\/([^\/]+)\/cat\/([^\/]+)\/?.*/, "$2") ))
            }

            if(path.indexOf("/tmpl/") > 0) { // TODO: надо переписать, чтоб в curTmpl лежал не сам шаблон, а только его код
                const tmplCodeByPath = decodeURIComponent(path.replace(/.*\/tmpl\/([^\/]+)\/?.*/, "$1"))
                if(curTmpl.code != tmplCodeByPath) {
                    if ("∅" === tmplCodeByPath) {
                        const tmplByPath = {code: tmplCodeByPath}
                        setCurTmpl(tmplByPath)
                        // showBuilder(tmplByPath)
                    } else {
                        if (allTemplates && allTemplates.length) {
                            const tmplByPath = allTemplates.filter(it => {
                                return (it.code == tmplCodeByPath)
                            })
                            if (tmplByPath.length) setCurTmpl( tmplByPath[0] ) // showBuilder(tmplByPath[0])
                        }
                    }
                }
            }

        }
    })

    useEffect(() => {
        if(curStorage) fetchCategories(setCategories, curStorage);
    }, [curStorage]);

    useEffect(() => {
        if(curStorage && categories) {
            fetchTemplates((tmpls)=>{
                setAllTemplates(tmpls);
            }, curStorage, curCategory);
            setCurCategory(allCats.code);
        }
    }, [categories]);

    useEffect(() => {
        if(curStorage && curTmpl && curTmpl.code ) {
            showBuilder( curTmpl )
        }
    }, [curTmpl]);

    useEffect(() => {
        if(allTemplates && allTemplates.length) {
            filter();

            if(path.indexOf("/tmpl/") > 0){
                const tmplCodeByPath = decodeURIComponent( path.replace(/.*\/tmpl\/([^\/]+)\/?.*/, "$1") )
                const tmplByPath = allTemplates.filter( it => {
                    return (it.code == tmplCodeByPath)
                })
                if(tmplByPath.length) // showBuilder( tmplByPath[0] )
                    setCurTmpl(tmplByPath[0]);
            }else{
                setCurTmpl({});
            }
        }
    }, [allTemplates, curCategory]);

    useEffect(() => {
        if(allTemplates) {
            const f = ((order.dir === "↓") ? 1 : -1);
            const k = order.by;
            setTemplates(templates.sort((a, b) => {
                if (a[k] >= b[k]) {
                    return 1 * f
                }
                if (a[k] <= b[k]) {
                    return -1 * f
                }
            }));
        }
    }, [order]);

    const deleteTemplate = function (template, setter) {
        if( curUserInfo && curUserInfo.sub ) {
            if (window.confirm(`Удалить шаблон «${template.code}»?`)) {
                let data = new FormData();
                data.append("requestType", "templateDelete");
                data.append("code", template.code);
                data.append("src", template.src);
                gFetch("/templates", {method: "POST", body: data})
                    .then( it => { return it.json() })
                    .then( it => {
                        if (it.code === template.code){
                            setter( allTemplates.filter( it => { return it.code !== template.code }) )
                        }else{
                            window.alert(it.code+": "+it.title+"\r\n"+it.description)
                        }
                    });
            }
        }
    }
    const saveTemplate = function (formData, callback) {
        if( curUserInfo && curUserInfo.sub ) {
            formData.set("src", curStorage)
            formData.set("requestType", "templateUpdate")
            gFetch("/templates", {method: "POST", body: formData})
                .then( it => { return it.json() })
                .then( it => {
                    if( it.type === "err" ){
                        alert( it.code +": "+ it.title + "\r\n\r\n" + it.description)
                    }else {
                        fetchTemplates( setAllTemplates, curStorage);
                        const tf = document.querySelector("#templates input[name='tfile']");
                        if(tf) tf.value = "";

                        callback && callback();
                    }
                });
        }else{
            alert("Вы не авторизованы")
        }
    }
    const showFormForEditTemplate = function (template) {
        if(template && template.code) {
            if (curUserInfo && curUserInfo.sub) {
                ReactDOM.createRoot(getOrCreate()).render((
                    <Editor
                        template={template}
                        onSave={(form) => {
                            // form.set("src", template.src)
                            saveTemplate(new FormData(form));
                            form.reset();
                        }}
                        categories={categories}
                    />
                ))
            } else {
                alert("Вы не авторизованы")
            }
        }
    }
    const uploadTemplate = function (e, setter) { //передавать имя хранилища, в которое добавляем - src
        e.preventDefault()
        if(curUserInfo.sub) {
            gFetch("/templates", {method: "POST", body: new FormData(e.target) })
                .then( it => { return it.json() })
                .then( it => {
                    if( it.type === "err" ){
                        alert( it.code +": "+ it.title + "\r\n\r\n" + it.description)
                    }else {
                        fetchTemplates(setter, curStorage);

                        const tf = document.querySelector(".delFile"); if(tf) tf.click();
                        showFormForEditTemplate(it)
                    }
                })
        }
    }

    const filter = function(){ // Поиск
        const byAll = document.getElementById("ifilter").value || "";
        const byCategory = curCategory || ""; // document.querySelector("#templatesList .template-category .filter [name]").value || "";
        // console.info("byCategory: ", byCategory);
        const filteredTemplates = allTemplates.filter( it => {
            if(byAll==="") return 1;
            const text = (
                        it.code
                + " " + it.title
                + " " + it.description
                + " " + it.keywords
                + " " + it.category
            )
                .trim()
                .toUpperCase()
            ;
            if(text.length>0){
                return (text.indexOf(byAll.trim().toUpperCase())+1);
            }else{
                return 0;
            }
        }).filter( it => {
            if ( byCategory === allCats.code) return true
                if ( byCategory === woCat.code) return (it.category||"")===""
                return it.category === byCategory
        })
        ;
        setTemplates( filteredTemplates );
    }

    const showBuilder = function (template) {
        if(template && template.code) {
            if(curUserInfo.sub) template.ua = curUserInfo.sub
            ReactDOM.createRoot(getOrCreate()).render((
                <Builder
                    template={template}
                    onClose={()=>{
                        // setCurTmpl({})
                        setPath(
                            "/src/" + encodeURIComponent(curStorage) + "/"
                            + "cat/" + encodeURIComponent(curCategory) + "/"
                            // + ( curTmpl.code ? "tmpl/" + encodeURIComponent(curTmpl.code) : "" )
                        )
                        setCurTmpl({})
                    }}
                />
            ))
        }
    }

    return (
        <>
            <div id="templates">

                <Storages
                    curStorageName={curStorage}
                    chStorage={(selectedStorage) => { // ГОВНОКОД дэтэктэд. TODO: сделать чтоб в curStorage был объект-хранилище, а не просто его название
                        // console.log("chStorage("+(selectedStorage.name)+")", selectedStorage)
                        setVisibleCategoryTree( selectedStorage.permissions.indexOf("catTree")>=0 )
                        setCanAddCategoryInRoot( selectedStorage.permissions.indexOf("catAdd")>=0 )
                        setTmplAdd( selectedStorage.permissions.indexOf("tmplAdd")>=0 )
                        setTmplAddWithoutCategory( selectedStorage.permissions.indexOf("tmplAddWithoutCategory")>=0 )
                        setPath("/src/"+encodeURIComponent(selectedStorage.name) + (( curCategory.code && [allCats.code, woCat.code].indexOf(curCategory.code) < 0) ? "/cat/"+ encodeURIComponent(curCategory.code) : "" ) + "/" )
                        // setStorage(selectedStorage.name)
                    }}
                />

                <fieldset id="filter">
                    <legend>Фильтр</legend>
                    <input
                        id="ifilter"
                        type="text"
                        onInput={() => {
                            filter()
                        }}
                    />
                </fieldset>

                <div id="mainData">
                    { visibleCategoryTree
                    ? <fieldset id="categoriesList" className={"categoriesList"}>
                            <Categories
                                categories={categories}
                                curent={curCategory}
                                allCategoriesSetter={(cats) => {
                                    setCategories(cats)
                                }}
                                curStorage={curStorage}
                                canAddCategory={canAddCategoryInRoot}
                                onChange={(cat) => {
                                    setPath("/src/" + encodeURIComponent(curStorage) + "/cat/" + encodeURIComponent(cat.code) + "/")
                                }}
                            />
                      </fieldset>
                    : <></>
                    }

                    <fieldset id="templatesList">
                        <table>
                            <thead>
                            <Template
                                onResort={(ord) => {
                                    setOrder(ord)
                                }}
                                order={order}
                            />
                            </thead>
                            <tbody>
                            {
                                templates.map(it => (<Template
                                    template={it}
                                    chTemplate={(it) => {
                                        setPath(
                                                "/src/" + encodeURIComponent(curStorage) + "/"
                                                + "cat/" + encodeURIComponent(curCategory) + "/"
                                                + "tmpl/" + encodeURIComponent(it.code)
                                            )
                                        }}
                                        delTemplate={(it) => {
                                            deleteTemplate(it, setAllTemplates);
                                        }}
                                        showFormForEditTemplate={(it) => {
                                            showFormForEditTemplate(it);
                                        }}
                                        current={curTmpl.code === it.code && curTmpl.src === it.src}
                                    />))
                                }
                            </tbody>
                        </table>

                        {!tmplAdd
                            ? ""
                            : (( ( !tmplAddWithoutCategory && [allCats.code, woCat.code].indexOf(curCategory) + 1 ) ? (
                                <fieldset id="templateAdd">
                                    Чтобы добавить шаблон в<br/><code>{curStorage}://</code><br/>
                                    необходимо выбрать определённую категорию
                                </fieldset>
                            ) : (
                                <form
                                    id="templateAdd"
                                    action="/templates"
                                    method="POST"
                                    encType="multipart/form-data"
                                    onSubmit={(e) => {
                                        uploadTemplate(e, setAllTemplates)
                                    }}>
                                    <fieldset>
                                        <ui.File
                                            id="addTFile"
                                            label={<>Добавить шаблон в<br/><code>{curStorage}://{curCategory}</code></>}
                                            name="tfile"
                                            value=""
                                            className=""
                                            placeholder="Файл не выбран"
                                            accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            onChange={o => {
                                                o.form.querySelector("input[type='submit']").disabled = !(o.value);
                                            }}
                                        />
                                        <input type="hidden" name="category" value={curCategory}/>
                                        <input type="hidden" name="src" value={curStorage}/>
                                        <input type="hidden" name="requestType" value="templateAdd"/>

                                        <input type="submit"
                                               id="upload"
                                               value="Загрузить"
                                               className="small"
                                               disabled="disabled"
                                        />

                                    </fieldset>
                                </form>
                            )))}
                    </fieldset>

                </div>
            </div>

            <button
                id="toGenerateWithoutTemlateInStorage"
                className="toGenerateWithoutTemlateInStorage"
                onClick={() => {
                    // showBuilder({code: "∅"}) // это такой будто "спец-шаблон"))
                    setPath("/"
                        + "src/" + encodeURIComponent(curStorage) + "/"
                        + "cat/" + encodeURIComponent(curCategory) + "/"
                        + "tmpl/" + encodeURIComponent("∅")
                    )
                }}
            >Сформировать документ без предварительно загруженного шаблона</button>
        </>
    );
}
