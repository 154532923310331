import React, {useEffect, useId, useState} from "react";
// import reactStringReplace from 'react-string-replace';
// import HighLighter from "../../../../core/HighLighter";
// import ReactDOM from "react-dom/client";

import Dialog from "../../../../core/Dialog";

export default function CategoryEditor({
                    cat = {}, // as Category
                    onSave = (form)=>{},
                  }){

    const id = useId()

    return (<Dialog
        id={"CategoryEditor"+id}
        title={"Категория "+cat.title}
        buttonCaption="Сохранить"
        buttonAction={(e, cb) => {
            onSave( document.getElementById("CategoryEditorForm"+id) );
            cb && cb(true);
        }}
    >
        <form className="dg"
              id={"CategoryEditorForm"+id}
                onSubmit={(e)=>{
                    e.preventDefault();
                    return;
                }}
        >
            <fieldset className=" w1">
                <legend>Наименование</legend>
                <input type="text" name="title" defaultValue={cat.title||""}  required />
            </fieldset>

                { cat.id ? <input type="hidden" name="id" value={cat.id} required readOnly /> : <></> }
                <input type="hidden" name="src" value={cat.src||""} required readOnly />
                <input type="hidden" name="category" value={cat.category||""} required readOnly />
                <input type="hidden" name="requestType" value={cat.id ? "categoryUpdate" : "categoryAdd"} required readOnly />
        </form>
    </Dialog>)
}
