import "./style.css"

import React, {useId, useState} from "react";

import ui from "../../../core/ui";
import Dialog from "../../../core/Dialog";
import HighLighter from "../../../core/HighLighter";
import ReactDOM from "react-dom/client";
import {getOrCreate} from "../../../core/fncs";
import Categories, {allCats, woCat} from "../Categories";

export default function Editor({
                                   template = {}, // as TemplateBaseInfo
                                   onSave = (form)=>{},
                                   categories = [],
                               }){

    const [activeTab, setActiveTab] = useState("main")
    const [category, setCategory] = useState(template.category)
    const [cache, setCache] = useState(template.cacheMaxAge||0)

    const id = useId()
    const idCatSelector = useId()

    const showCategorySelector = ()=>{
        const catSelectorContainer = getOrCreate(idCatSelector)
        ReactDOM.createRoot(catSelectorContainer).render((
            <Dialog
                title={<>
                    <ui.Ico i={"folder-tree"}/>
                    Выбор категории
                </>}
                className={"categoriesList"}
            >
                <Categories
                    categories={categories}
                    onChange={(cat)=>{
                        // template.category=cat.title
                        setCategory( cat.title )
                        catSelectorContainer.remove()
                    }}
                    curent={category}
                    withSystems={false}
                />
            </Dialog>
        ))
    }

    return (<Dialog
        id={"Editor"+id}
        className={"template-editor type-" + template.type + " baseFormat-" + template.type.slice(0, 3)}
        title={<>
            <ui.Ico i={"square-pen"}/>
            Редактирование шаблона {template.code}
        </>}
        buttonCaption="Сохранить"
        buttonAction={(e, cb) => {
            onSave( document.getElementById("EditorForm"+id) );
            cb && cb(true);
        }}
    >
        <form className={"tabs"}
              id={"EditorForm" + id}
              onSubmit={(e) => {
                  e.preventDefault();
                  // onSave(e.target);
                  return;
              }}
        >

            <div className="tablist" role="tablist" aria-labelledby="label">
                <button className="tab" role="tab" type="button"
                        id={"EditorForm-tab" + id + "-main"}
                        aria-controls={"EditorForm-tabpanel" + id + "-main"}
                        onClick={()=>{setActiveTab("main")}} aria-selected={(activeTab=="main")} tabIndex={(activeTab=="main" ? "0" : "-1")}
                >Основное</button>
                <button className="tab" role="tab" type="button"
                        id={"EditorForm-tab" + id + "-form"}
                        aria-controls={"EditorForm-tabpanel" + id + "-form"}
                        onClick={() => {
                            setActiveTab("form")
                        }} aria-selected={(activeTab == "form")} tabIndex={(activeTab == "form" ? "0" : "-1")}
                >Форма
                </button>
                <button className="tab" role="tab" type="button"
                        id={"EditorForm-tab" + id + "-example"}
                        aria-controls={"EditorForm-tabpanel" + id + "-example"}
                        onClick={()=>{setActiveTab("example")}} aria-selected={(activeTab=="example")} tabIndex={(activeTab=="example" ? "0" : "-1")}
                >Пример</button>
                {0 > template.permissions.indexOf("shw_generator")
                    ? <></>
                    : <button className="tab" role="tab" type="button"
                              id={"EditorForm-tab" + id + "-generator"}
                              aria-controls={"EditorForm-tabpanel" + id + "-generator"}
                              onClick={() => {
                                  setActiveTab("generator")
                              }} aria-selected={(activeTab == "generator")}
                              tabIndex={(activeTab == "generator" ? "0" : "-1")}
                    >Генератор</button>
                }
            </div>

            <div className={"tabpanel EditorForm-tabpanel-main "+((activeTab=="main") ? "" : "tabpanel--hidden")} role="tabpanel" id={"EditorForm-tabpanel" + id + "-main"} tabIndex="0"
                 aria-labelledby={"EditorForm-tab" + id + "-main"}>

                <fieldset className="template-code">
                    <legend>Код</legend>
                    <input type="text" name="code" value={template.code || ""} readOnly required/>
                </fieldset>
                <fieldset className="template-title">
                    <legend>Наименование</legend>
                    <input type="text" name="title" defaultValue={template.title || ""} required readOnly={0>template.permissions.indexOf("upd_title")} />
                </fieldset>
                <fieldset className="template-category">
                    <legend>Категория</legend>
                    <input type="text" name="category" value={category}
                           onClick={(e)=>{
                               (0<=template.permissions.indexOf("upd_category")) && showCategorySelector(e)
                           }}
                           required={0<=template.permissions.indexOf("upd_category")}
                           readOnly={0>template.permissions.indexOf("upd_category")}
                    />
                </fieldset>
                <fieldset className="template-keywords">
                    <legend>Ключевые слова</legend>
                    <input type="text" name="keywords" defaultValue={template.keywords || ""} readOnly={0>template.permissions.indexOf("upd_keywords")} />
                </fieldset>
                <fieldset className="template-description">
                    <legend>Описание</legend>
                    <textarea name="description" defaultValue={template.description || ""} readOnly={0>template.permissions.indexOf("upd_description")} />
                </fieldset>
                <fieldset className="template-cacheMaxAge">
                    <legend>Актуальность кеша</legend>
                    <p>
                        <label>
                        <input type={"radio"} checked={cache < 0}  onClick={(e)=>{ setCache(-3600000) }}  readOnly={0>template.permissions.indexOf("upd_cache")} /> Не кэшировать</label>
                        <label><input type={"number"} value={ (cache <= 0) ? "" : (cache||0)/3600000} onInput={(e)=>{ setCache(e.target.value * 3600000) }}  readOnly={0>template.permissions.indexOf("upd_cache")} /> часов</label>
                        <label><input type={"radio"} checked={cache == 0} onClick={(e)=>{ setCache(0) }}  readOnly={0>template.permissions.indexOf("upd_cache")} /> Вечно</label>
                    </p>
                    <input type="hidden" name="cacheMaxAge" value={cache}/>
                </fieldset>
                <fieldset className="template-tfile">
                    <legend>Файл шаблона</legend>
                    <ui.File
                        name="tfile"
                        value={template.code + "." + template.type}
                        placeholder="Файл не выбран"
                        accept={template.type.slice(0, 3) === "doc" ? ".docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" : ".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"}
                        onChange={(inputField) => {
                            //При выборе нового файл шаблона, кнопка "Скачать" исчезает (появляется крестик отмены выбора); при отмене выбранного файла, возвращается кнопка "Скачать"
                            document.getElementById(`dwnld-${id}`).style.display = (inputField.value ? "none" : "");
                        }}
                        readOnly={0>template.permissions.indexOf("upd_file")}
                    />
                    <a id={"dwnld-" + id} className="button small download"
                       href={"/render?tcode=" + template.code + "&src=" + template.src}>
                        <ui.Ico i="download fa-"/>
                        Скачать
                    </a>
                </fieldset>

                {(template.type.slice(0, 3) === "doc")
                    ? <fieldset className="template-mixaly4">
                        <legend>Mixaly4-Style</legend>
                        <ui.Checkbox
                            name="mixaly4"
                            value="true"
                            checked={template.mixaly4 || false}
                            readOnly={0>template.permissions.indexOf("upd_docstyle")}
                        />
                    </fieldset>
                    : <></>
                }
            </div>
            <div className={"tabpanel EditorForm-tabpanel-form "+((activeTab=="form") ? "" : "tabpanel--hidden" )} role="tabpanel" id={"EditorForm-tabpanel" + id + "-form"} tabIndex="0"
                 aria-labelledby={"EditorForm-tab" + id + "-form"}>
                <fieldset className="template-form">
                    <legend>Форма запроса данных</legend>
                    {/*<textarea name="form" defaultValue={template.form||""} />*/}
                    <HighLighter
                        name="form"
                        content={template.form || ""}
                        language="xml"
                        readOnly={0>template.permissions.indexOf("upd_form")}
                    />
                </fieldset>
            </div>
            <div className={"tabpanel EditorForm-tabpanel-example "+((activeTab=="example") ? "" : "tabpanel--hidden" )} role="tabpanel" id={"EditorForm-tabpanel" + id + "-example"} tabIndex="0"
                 aria-labelledby={"EditorForm-tab" + id + "-example"}>
                <fieldset className="template-example">
                    <legend>Пример данных</legend>
                    {/*<textarea name="example" defaultValue={template.example||""} />*/}
                    <HighLighter
                        name="example"
                        content={template.example || ""}
                        language="xml"
                        readOnly={0>template.permissions.indexOf("upd_example")}
                    />
                </fieldset>
            </div>
            {0 > template.permissions.indexOf("shw_generator")
                ? <></>
                : <div className={"tabpanel EditorForm-tabpanel-generator "+((activeTab=="generator") ? "" : "tabpanel--hidden")} role="tabpanel" id={"EditorForm-tabpanel" + id + "-generator"} tabIndex="0"
                       aria-labelledby={"EditorForm-tab" + id + "-generator"}>
                    <fieldset className="template-generator">
                        <legend>Код генерирования данных</legend>
                        {/*<textarea name="generator" defaultValue={template.datagenerator||""} />*/}
                        <HighLighter
                            name="generator"
                            content={template.generator || ""}
                            language="sql"
                            readOnly={0>template.permissions.indexOf("upd_generator")}
                        />
                    </fieldset>
                </div>
            }

            {/*<!--
            <fieldset class="template-type">
                <legend>Тип</legend>
                <input type="text" name="type" defaultValue="${template.type||""}"  required readonly />
            </fieldset>
            <fieldset class="template-author">
                <legend>Автор(id)</legend>
                <input type="text" name="author" defaultValue="${(template.author && template.author.sub)||""}"  required readonly />
            </fieldset>
            <fieldset class="template-img">
                <legend>Картинка</legend>
                <input type="text" name="img" defaultValue="${template.img||""}" />
            </fieldset>
            -->*/}
        </form>
    </Dialog>)
}
